import dictionary from "../dictionary";
import { MamTenisBox } from "./style";
const status = dictionary.tenis.status;

const Tenis = ({ data, type }) => {
  // console.log("tenis", data, type);
  data.status = "playing";
  console.warn(data);
  const getStatus = () => {
    if (status[data.status.toLowerCase()].active) {
      return (
        <p>
          {status[data.status.toLowerCase()].name} - {data.home.score?.length}° Set
        </p>
      );
    } else {
      return <p>{status[data.status.toLowerCase()].name}</p>;
    }
  };

  const getPlayers = (team) => {
    if (data[team] && data[team].players) {
      if (Array.isArray(data[team].players)) {
        return data[team].players.length > 0 ? (
          data[team].players.map((element) => {
            let [playerName, ...rest] = element.name.split(" ");
            let displayName = playerName[0] + ". " + rest;
            return (
              <p>
                {displayName}{" "}
                {element.tiebreakScore && (
                  <span>({element.tiebreakScore})</span>
                )}
              </p>
            );
          })
        ) : (
          <></>
        );
      } else return "";
    }
  };

  const getPlayersNewsMobile = (team) => {
    if (data[team] && data[team].players) {
      if (Array.isArray(data[team].players)) {
        return data[team].players.length > 0 ? (
          data[team].players.map((element) => {
            let splittedText = element.name.split(" ");
            let playerName = splittedText[0];
            let playerLastname = splittedText[splittedText.length - 1];
            let displayName =
              playerName[0] + playerLastname[0] + playerLastname[1];
            return (
              <p>
                {displayName}{" "}
                {element.tiebreakScore && (
                  <span>({element.tiebreakScore})</span>
                )}
              </p>
            );
          })
        ) : (
          <></>
        );
      } else return "";
    }
  };

  const getGames = () => {
    const games = [];
    for (let index = 0; index < data.games; index++) {
      games.push(index);
    }
    return games.map((element, index) => (
      <div
        className="game-set-number-container"
        key={index}
        style={{
          background:
            index === data.games - 1 && data.status === "playing"
              ? "#CC001B"
              : "",
        }}
      >
        <p
          className="game-set-number"
          style={{
            background:
              index === data.games - 1 && data.status === "playing"
                ? "#CC001B"
                : "",
            color:
              index === data.games - 1 && data.status === "playing"
                ? "white"
                : "",
          }}
        >
          {index + 1}
        </p>
        {getScore("home", index)}
        {getScore("away", index)}
      </div>
    ));
  };

  const getScore = (team, setIndex) => {
    if (data[team] && data[team].score && Array.isArray(data[team].score)) {
      const score = data[team].score[setIndex];
      if (score) {
        return (
          <p className="game-score" key={setIndex}>
            <span>{score.score}</span>
            {score.tiebreakScore ? <span>({score.tiebreakScore})</span> : ""}
          </p>
        );
      }
    }
    return <p className="game-score">-</p>;
  };

  return (
    <>
      <MamTenisBox className="tenis-mam" type={type} status={status[data.status.toLowerCase()]?.name}>
        {status[data.status.toLowerCase()]?.name == "fixture" ? (
          <div className="box-fixture">
            {type === "home" ? (
              <>
                <p className="player-name home">{getPlayers("home")}</p>
                <div className="game-info">
                  <p className="game-info-text">Inicio</p>
                  <p>18:00</p>
                </div>
                <p className="player-name away">{getPlayers("away")}</p>
              </>
            ) : (
              <>
                <p className="player-name home">{getPlayers("home")}</p>

                <div className="game-info-news">
                  <p className="game-info-day">DOMINGO 8.06.2023</p>
                  <div className="game-info-hour-container">
                    <p className="game-info-hour">18:00</p>
                  </div>
                </div>

                <p className="player-name away">{getPlayers("away")}</p>
              </>
            )}
          </div>
        ) : (
          <div className="box-initialized-container">
            {type === "news" && (
              <div className="game-info-text-container">
                <div className="game-info-text">
                  {getStatus()}
                </div>
                <div className="only-mobile">
                  {getPlayers("home")} VS. {getPlayers("away")}
                </div>
              </div>
            )}

            <div className="box-initialized">
              <div className="game-players">
                <div className="team local">
                  <div className="player-name">{getPlayers("home")}</div>
                  <div className="player-name-mobile">
                    {getPlayersNewsMobile("home")}
                  </div>
                </div>

                {type === "home" && (
                  <div className="team visitante">
                    <div className="player-name">{getPlayers("away")}</div>
                  </div>
                )}
              </div>

              <div className="game-sets">
                {getGames()}
                {type === "news" && <span className="line-news"></span>}
              </div>

              {type === "news" && (
                <div className="team visitante">
                  <div className="player-name">{getPlayers("away")}</div>
                  <div className="player-name-mobile">
                    {getPlayersNewsMobile("away")}
                  </div>
                </div>
              )}

              <div className="line"></div>
            </div>
          </div>
        )}
      </MamTenisBox>
    </>
  );
};
export default Tenis;
