import { useRef, useLayoutEffect } from "react";
import dictionary from "../dictionary";
import Banner from "@/widgets/Banner/Templates/Banner";
import { MamRugbyBox, Team } from "./style";
import { getHourMinute } from "@/shared/Layout/helpers";
import { getDateWithDayAndPeriod } from "helpers";
import { getHourMinutesTwo } from "@/helpers/formatDate";
const status = dictionary.rugby.status;
const Rugby = ({ data, type, bannerId }) => {
  const detailGoalsRef = useRef(null);
  const lineRef = useRef(null);

  useLayoutEffect(() => {
    if (type === "home") {
      return;
    }

    if (detailGoalsRef && lineRef && lineRef.current) {
      let height = detailGoalsRef?.current?.offsetHeight;
      lineRef.current.style.minHeight = `${height}px`;
    }
  }, [data]);

  function getMatchDate() {
    const [day, month, year] = data.date.split("-");
    return <>{getDateWithDayAndPeriod(`${year}/${month}/${day}`)}</>;
  }

  return (
    <>
      <MamRugbyBox className="mam" type={type}>
        <div className="box rubgy-box">
          <div className="flex header">
            <Team className="localTeam" type={type}>
              {type != "home" && (
                <figure className="shield-mam">
                  <img
                    alt={data.home.name}
                    src={`${process.env.NEXT_PUBLIC_IMAGE_DOMAIN}/collections/escudos/rugby/${data.home._id}.png`}
                  />
                </figure>
              )}
              <p className={`${status[data.status.toLowerCase()]?.class}`}>
                {data.home.name}
              </p>
            </Team>

            <div
              className={`header-info ${
                status[data.status.toLowerCase()]?.class
              }`}
            >
              <div className="status">
                {type == "news" ? (
                  <>
                    <p
                      className={`status-mathc ${
                        status[data.status.toLowerCase()]?.class
                      }`}
                    >
                      {data.status.toLowerCase() !== "fixture"
                        ? status[data.status.toLowerCase()]?.name
                        : getMatchDate()}
                    </p>
                    {data.status.toLowerCase() !== "result" &&
                      data.status.toLowerCase() !== "fixture" && (
                        <p className="status-mathc">
                          {status[data.status.toLowerCase()]?.perdiod}
                        </p>
                      )}
                    {data.status.toLowerCase() !== "result" &&
                      data.status.toLowerCase() !== "fixture" && (
                        <p className="status-mathc">{data?.minute} - min</p>
                      )}
                  </>
                ) : (
                  <p
                    className={`status-mathc ${
                      status[data.status.toLowerCase()]?.class
                    }`}
                  >
                    {data.status.toLowerCase() == "result"
                      ? status[data.status.toLowerCase()]?.name
                      : status[data.status.toLowerCase()]?.perdiod}
                  </p>
                )}
              </div>

              {status[data.status.toLowerCase()]?.name == "fixture" ? (
                <div className="info-time">
                  <p>{getHourMinutesTwo(data?.time)}</p>
                </div>
              ) : (
                <div className="results">
                  <div
                    className={`results-local ${
                      status[data.status.toLowerCase()]?.class
                    }`}
                  >
                    <p>{data.home.score}</p>
                  </div>

                  {type === "home" && <p className="results-only-home">-</p>}
                  <div
                    className={`results-visit ${
                      status[data.status.toLowerCase()]?.class
                    }`}
                  >
                    <p>{data.away.score}</p>
                  </div>
                </div>
              )}
            </div>

            <Team className="visitTeam" type={type}>
              {type != "home" && (
                <figure className="shield-mam">
                  <img
                    alt={data.away.name}
                    src={`${process.env.NEXT_PUBLIC_IMAGE_DOMAIN}/collections/escudos/rugby/${data.away._id}.png`}
                  />
                </figure>
              )}
              <p className={`${status[data.status.toLowerCase()]?.class}`}>
                {data.away.name}
              </p>
            </Team>
          </div>
          {type != "home" && (
            <div className="flex details-goals-container">
              {data.status !== "Fixture" &&
                data.home.goals.length > 0 &&
                data.away.goals.length > 0 && (
                  <div className="details-goals" ref={detailGoalsRef}>
                    <div className="details-goals-local">
                      {data.home.goals
                        .filter((goal) => goal.code !== "PENG")
                        .sort((a, b) => parseInt(a.minute) - parseInt(b.minute))
                        .map((element, key) => (
                          <p
                            className={`details-goals-text ${
                              data.status === "Played" ? "played" : ""
                            }`}
                            key={key}
                          >
                            {`${element.playerName} ${
                              element.code === "TRY" ? "(TRY)" : "(PEN)"
                            } ${element.minute}'`}
                          </p>
                        ))}
                    </div>

                    {(data.away.goals && data.away.goals.length > 0) ||
                    (data.home.goals && data.home.goals.length > 0) ? (
                      <div className="details-line" ref={lineRef}></div>
                    ) : null}

                    <div className="details-goals-visit">
                      {data.away.goals
                        .filter((goal) => goal.code !== "PENG")
                        .sort((a, b) => parseInt(a.minute) - parseInt(b.minute))
                        .map((element, key) => (
                          <p
                            className={`details-goals-text ${
                              data.status === "Played" ? "played" : ""
                            }`}
                            key={key}
                          >
                            {`${element.playerName} ${
                              element.code === "TRY" ? "(TRY)" : "(PEN)"
                            } ${element.minute}'`}
                          </p>
                        ))}
                    </div>
                  </div>
                )}
            </div>
          )}

          {/* <div className="right-box">
            <div className="sponsor-mam">
              <Banner slotId={bannerId} />
            </div>
          </div> */}
        </div>
      </MamRugbyBox>
    </>
  );
};
export default Rugby;
