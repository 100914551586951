import Clock from "../Clock";
import dictionary from "../dictionary";
import { FutbolCard, DotResults } from "./style";
import { getDateWithDayAndPeriod } from "helpers";
const status = dictionary.futbol.status;
const period = dictionary.futbol.period;

const FutbolNews = ({ data }) => {
  const getArrayGoals = (goals, team) => {
    if (goals && team) {
      if (Array.isArray(goals)) {
        return goals.length > 0
          ? goals
              .map((element) => {
                element.team = data[team].shortName;
                return element;
              })
              .filter((element) => element.match_period !== "PS")
          : [];
      }
    }
    return [];
  };

  const getGoals = () => {
    if (data.status !== "Fixture") {
      let home = getArrayGoals(data.home.goals, "home");
      let away = getArrayGoals(data.away.goals, "away");

      let sortedHomeGoals = home.sort(
        (a, b) => parseInt(a.minute) - parseInt(b.minute)
      );

      let sortedAwayGoals = away.sort(
        (a, b) => parseInt(a.minute) - parseInt(b.minute)
      );

      return (
        <div className="futbol-details-goals">
          <div className="futbol-details-goals-local">
            {sortedHomeGoals && sortedHomeGoals.length > 0
              ? sortedHomeGoals.map((element, key) => (
                  <p
                    className={`futbol-details-goals-text ${
                      data.status === "Played" ? "played" : ""
                    }`}
                  >
                    {`${element.playerName} ${
                      element.code == "OWNG" ? "(GC)" : ""
                    }${" "}
                  ${element.code == "PENG" ? "(PEN)" : ""} ${element.minute}'`}
                  </p>
                ))
              : ""}
          </div>

          {sortedAwayGoals &&
          sortedAwayGoals.length > 0 ||
          sortedHomeGoals &&
          sortedHomeGoals.length > 0 ? (
            <div className="futbol-details-line"></div>
          ) : null}

          <div className="futbol-details-goals-visit">
            {sortedAwayGoals && sortedAwayGoals.length > 0
              ? sortedAwayGoals.map((element, key) => (
                  <p
                    className={`futbol-details-goals-text ${
                      data.status === "Played" ? "played" : ""
                    }`}
                  >
                    {`${element.code == "PENG" ? "(PEN)" : ""} ${
                      element.minute
                    }'${" "}${element.playerName} ${
                      element.code == "OWNG" ? "(GC)" : ""
                    }
                  `}
                  </p>
                ))
              : ""}
          </div>
        </div>
      );
    } else {
      return null;
      //no se tiene este dato
      // const previousResults = {
      //   home: ["V", "V", "V", "D", "D"],
      //   away: ["E", "V", "D", "V", "V"],
      // };
      // return (
      //   <>
      //     <div className="futbol-details-previous local">
      //       {previousResults.home.map((result) => {
      //         return (
      //           <span>
      //             {/* <DotResults $result={result} /> */}
      //             <p className="futbol-details-previous-result">{result}</p>
      //           </span>
      //         );
      //       })}
      //     </div>

      //     <div className="futbol-details-line"></div>

      //     <div className="futbol-details-previous visit">
      //       {previousResults.away.map((result) => {
      //         return (
      //           <span>
      //             {/* <DotResults $result={result} /> */}
      //             <p className="futbol-details-previous-result">{result}</p>
      //           </span>
      //         );
      //       })}
      //     </div>
      //   </>
      // );
    }
  };

  const getStatus = () => {
    if (data.status === "Fixture") {
      const [day, month, year] = data.date.split("-");
      return <>{getDateWithDayAndPeriod(`${year}/${month}/${day}`)}</>;
    } else {
      return <>{getPeriod()}</>;
    }
  };

  const getPeriod = () => {
    return (
      <>
        <p>{status[data.status.toLowerCase()]?.name}</p>
        <p>
          {period[data?.match_period?.toLowerCase()]?.clockActive && (
            <>
              <Clock data={data} /> -{" "}
            </>
          )}{" "}
          {period[data?.match_period?.toLowerCase()]?.description}
        </p>
      </>
    );
  };

  const getResult = () => {
    if (status[data?.status?.toLowerCase()]?.name === "fixture") {
      return (
        <div className="futbol-header-info-time">
          <p>{data.time.slice(0, 5)}</p>
        </div>
      );
    }
    return (
      <div className="futbol-header-info-goals">
        <div
          className={`futbol-header-info-local ${
            data.status === "Played" ? "played" : ""
          }`}
        >
          <p>{data.home.score}</p>
          {(data.home.golesDefPenales > 0 || data.away.golesDefPenales > 0) && (
            <sub>{`(${data.home.golesDefPenales})`}</sub>
          )}
        </div>
        <div
          className={`futbol-header-info-visit ${
            data.status === "Played" ? "played" : ""
          }`}
        >
          <p>{data.away.score}</p>
          {(data.home.golesDefPenales > 0 || data.away.golesDefPenales > 0) && (
            <sub>{`(${data.away.golesDefPenales})`}</sub>
          )}
        </div>
      </div>
    );
  };

  return (
    <FutbolCard className="mam">
      <div className="futbol-header">
        <div className="futbol-header-team local">
          <figure className="futbol-team-img">
            <img
              width="80px"
              height="80px"
              className="futbol-team-shield"
              alt={data.home.name}
              src={`https://www.clarin.com/img/collections/escudos/${data.home._id}.png`}
            />
          </figure>

          <p className="futbol-team-name">{data.home.name}</p>
        </div>

        <div className="futbol-header-info">
          <p className="futbol-header-info-text">{getStatus()}</p>
          <div className="futbol-header-info-details">{getResult()}</div>
        </div>

        <div className="futbol-header-team visit">
          <figure className="futbol-team-img">
            <img
              width="80px"
              height="80px"
              className="futbol-team-shield"
              alt={data.away.name}
              src={`https://www.clarin.com/img/collections/escudos/${data.away._id}.png`}
            />
          </figure>

          <p className="futbol-team-name">{data.away.name}</p>
        </div>
      </div>

      <div className="futbol-details">
        {/* no se tiene este dato */}
        {/* <div className="futbol-details-header">
          {data.status === "Fixture" ? (
            <p className="">CÓMO LLEGAN LOS EQUIPOS</p>
          ) : (
            <ContentIcon
              fill={data.status === "Played" ? "#555" : "#000"}
              width="20px"
              height="20px"
              nameIcon="futbol"
            />
          )}
        </div> */}

        <div className="futbol-details-info">{getGoals()}</div>
      </div>
    </FutbolCard>
  );
};

export default FutbolNews;
