import styled from "styled-components";
import { Color, Font, breakpoints, ColorDark } from "@/shared/Constants";

export const MamRugbyBox = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 3;
  padding: 5px;
  align-items: center;
  position: absolute;
  ${(props) =>
    props.type == "home" &&
    `
    padding: 0px; 
    bottom: -28px;

    ${breakpoints.phone} {
      bottom: 0px;
    }
  `};

  ${(props) =>
    props.type == "news" &&
    `position:relative; 
    bottom: 0;
    left: 0; 
    margin: 5px 0px;
    margin-left: calc((100% - 898px - 1rem) / 2);
    max-width: 600px;
    width: 70%;  
    `};
  ${breakpoints.phone} {
    ${(props) =>
      props.type == "news" &&
      `
    margin-left: 0;
    max-width: 100%;
    width: 100%;`};
  }

  .box {
    ${(props) =>
      props.type == "news" &&
      ` width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 40px;
        ${breakpoints.phone} {
            margin: auto;
            margin-bottom: 40px;
            width: 100%;
        }`};
    ${(props) =>
      props.type == "home" &&
      `display: flex;
      justify-content: center;
      height: 28px;
      width: 100%;
      background: ${Color.black000};
    `};
    ${breakpoints.phone} {
      ${(props) =>
        props.type == "news" &&
        " flex-direction:column;   align-items:center;  "};
    }
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
  }
  .header {
    ${(props) =>
      props.type == "news" &&
      `width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      `}
    ${(props) =>
      props.type == "home" &&
      `align-items: flex-end;
        `};
    .header-info {
      ${(props) =>
        props.type == "home" &&
        `background-color: ${Color.mainColor};
          padding: 0px 11px;
          display: flex;
          flex-direction: column;
        `};
      ${(props) =>
        props.type == "news" &&
        `
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 30px;
        margin-top: 50px;
        width: 25%;
        ${breakpoints.phone} {
          margin-top: 22px;
          gap: 15px;
        }`};

      .status {
        ${(props) =>
          props.type == "home" &&
          `
          background-color: ${Color.mainColor};
        `};
        p {
          text-align: center;
          ${(props) =>
            props.type == "news" &&
            `
            ${Font.sansRegular}
            font-size: 14px;
            line-height: 20px;
            color: ${Color.black};
            text-transform: uppercase;
            ${breakpoints.phone} {
              font-size: 11px;
            }
            ${breakpoints.darkMode} {
                [data-theme='dark'] & {
            color: ${ColorDark.textLight};
          }
          }
            `};

          ${(props) =>
            props.type == "home" &&
            `
            ${Font.sansSemiBold}
            font-size: 11px;
            line-height: 11px;
            padding: 0px 11px;
            padding-top: 6px;
            color: ${Color.white};
            ${breakpoints.phone} {
              font-size: 11px;
            }
            ${breakpoints.darkMode} {
                [data-theme='dark'] & {
            color: ${ColorDark.textLight};
          }
          }
            `};
        }
      }
      .status-mathc.fixture {
        ${(props) =>
          props.type == "home" &&
          `
        background-color: ${Color.greyf1f1f1};
        color: ${Color.black000};
        ${breakpoints.darkMode} {

                [data-theme='dark'] & {
          color: ${ColorDark.textLight};
        }
        }
        `}
      }
      .status-mathc.finalized {
        ${(props) =>
          props.type == "home" &&
          `
        background-color: ${Color.black000};
        color: ${Color.white};
        ${breakpoints.darkMode} {

                [data-theme='dark'] & {
          color: ${ColorDark.textLight};
        }
        }
        `}
      }
      .info-time {
        ${(props) =>
          props.type == "news" &&
          `width: 130px;
          height: 73px;
          background-color: ${Color.grey};
          border-radius: 5px;
          text-align: center;
          padding: 8px;
          padding-top: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          ${breakpoints.phone} {
            padding: 6px;
            padding-top: 8px;
            width: 95px;
            height: 65px;
          }
          p {
            ${Font.sansBold}
            font-size: 40px;
            line-height: 35px;
            color: ${Color.white};
            padding-top: 5px;
            ${breakpoints.phone} {
              font-size: 30px;
              padding-top: 0;
            }
            ${breakpoints.darkMode} {

                [data-theme='dark'] & {
              color: ${ColorDark.textLight};
        }
            }
        }`};
        ${(props) =>
          props.type == "home" &&
          `
          p {
            font-size: 13px;
            line-heigth: 26px;
            color: ${Color.black000};
            ${Font.sansBold};
            position: relative;
            padding: 10px 0px;
            text-align: center;
            ${breakpoints.darkMode} {

                [data-theme='dark'] & {
              color: ${ColorDark.textLight};
        }
            }
          }
          `};
      }
      .results {
        ${(props) =>
          props.type == "news" &&
          `
          gap: 30px;
          width: 130px;
          height: 73px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 7px;
          ${breakpoints.phone} {
            width: 95px;
            height: 65px;
          }`};
        ${(props) =>
          props.type == "home" &&
          `
          display: flex;
          align-items: center;
          justify-content: center;
        
        `};
        p {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
          font-size: 35px;
          color: ${Color.black};
          text-transform: uppercase;
          ${Font.sansSemiBold};
          position: relative;
          ${(props) =>
            props.type == "home" &&
            `
            padding: 2px 0px;
          `};
          sub {
            vertical-align: sub;
            font-size: 18px;
          }
        }
        .results-only-home {
          ${Font.sansBold}
          font-size: 16px;
          line-height: 26px;
          color: ${Color.white};
          padding: 0 2px;
        }
        .results-local,
        .results-visit {
          ${(props) =>
            props.type == "news" &&
            `
            width: 50%;
            background-color: ${Color.black000};
            border-radius: 5px;
            text-align: center;
            height: 75px;
            display: flex;
            justify-content: center;
            align-items: center;
            ${breakpoints.phone} {
              height: 65px;
            }
          `};
          p {
            ${Font.sansBold}
            ${(props) =>
              props.type == "news" &&
              `
              font-size: 40px;
              line-height: 35px;
              padding-top: 5px;
              color: ${Color.white};`};
            ${(props) =>
              props.type == "home" &&
              `
              font-size: 16px;
              line-height: 26px;
              color: ${Color.white};`};
            ${breakpoints.phone} {
              ${(props) => props.type == "news" && "font-size: 30px;"};
            }
          }
          /* padding: 16px; */
          ${breakpoints.phone} {
            ${(props) => props.type == "news" && "padding: 6px 0px;"};
          }
        }
        .finalized,
        .fixture {
          ${(props) =>
            props.type == "news" && `background-color: ${Color.grey};`}
        }
      }
    }
    .header-info.in-progress {
      ${(props) => props.type == "news" && `margin-top: 20px;`}
    }
    .header-info.fixture {
      ${(props) =>
        props.type == "home" && `background-color: ${Color.greyf1f1f1};`}
    }
    .header-info.finalized {
      ${(props) =>
        props.type == "home" && `background-color: ${Color.black000};`}
    }
  }
  .details-goals-container {
    width: 100%;
    .details-goals {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      gap: 15px;
      margin-top: 12px;
      .details-goals-local,
      .details-goals-visit {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
        padding-right: 15px;
        ${breakpoints.phone} {
          padding-right: 0;
        }
        width: 40%;
        .details-goals-text {
          text-align: right;
          ${Font.sansRegular}
          font-size: 14px;
          line-height: 24px;
          color: ${Color.black000};
          ${breakpoints.phone} {
            font-size: 12px;
          }
          ${breakpoints.darkMode} {

            [data-theme='dark'] & {
            color: ${ColorDark.textLight};
            }
          }
        }
        .played {
          color: ${Color.grey};
          ${breakpoints.darkMode} {
            [data-theme='dark'] & {
            color: ${ColorDark.textLight};
            }
          }
        }
      }
      .details-goals-visit {
        align-items: flex-start;
        padding-left: 15px;
        ${breakpoints.phone} {
          padding-left: 0;
        }
        .details-goals-text {
          text-align: left;
        }
      }
    }
    .details-line {
      border-left: 2px dotted ${Color.grey};
      opacity: 0.5;
    }
  }
  .localTeam {
    align-items: flex-end;
    ${breakpoints.phone} {
      align-items: center;
    }
    p {
      text-align: right;
      ${breakpoints.phone} {
        text-align: center;
      }
    }
  }
  .visitTeam {
    align-items: flex-start;
    ${breakpoints.phone} {
      align-items: center;
    }
    p {
      text-align: left;
      ${breakpoints.phone} {
        text-align: center;
      }
    }
  }
`;

export const Team = styled.div.attrs((props) => ({
  className: props.className,
}))`
  display: flex;
  align-items: center;
  flex-direction: column;
  ${(props) =>
    props.type == "home" &&
    `
    padding: 7px 10px;
  `}
  ${(props) =>
    props.type == "news" &&
    `
    font-size: 26px;  
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 180px;
    ${breakpoints.phone} {
      gap: 18px;
      width: 30%;
      padding-top: 0;
    }`};

  p {
    ${(props) =>
      props.type == "news" &&
      `
    font-size: 26px;
    line-height: 21px;
    color:#000;
    ${Font.sansSemiBold};`};
    display: flex;
    color: ${Color.black};
    ${breakpoints.darkMode} {
      [data-theme='dark'] & {
      color: ${ColorDark.textLight};
      }
    }
    ${(props) =>
      props.type == "home" &&
      `
      ${Font.sansBold};
      color:${Color.white};
      font-size: 13px;
      line-height: 14px;
      ${breakpoints.darkMode} {
                [data-theme='dark'] & {
        color: ${ColorDark.textLight};
    }
      }
      `};
    max-width: 150px;
    ${breakpoints.phone} {
      ${(props) => props.type == "news" && " font-size: 18px;"};
    }
  }
  .finalized,
  .fixture {
    ${(props) =>
      props.type == "news" &&
      `
    color: ${Color.grey};
    ${breakpoints.darkMode} {
                [data-theme='dark'] & {
      color: ${ColorDark.textLight};
    }
    }
    `}
  }
  .shield-mam {
    width: 80px;
    height: 80px;
    margin: 0;
    ${breakpoints.phone} {
      width: 50px;
      height: 50px;
    }
    img {
      max-width: 100%;
      width: 100%;
    }
  }
`;
