import styled from "styled-components";
import { Color, Font, breakpoints, ColorDark } from "@/shared/Constants";

export const Dummy = styled.section`
    background-color: ${Color.greyf1f1f1} ;
    width: 100%;
    height: 30px;
    position: absolute;
    bottom: -30px;
    ${breakpoints.darkMode} {

        [data-theme='dark'] & {
        background-color: ${ColorDark.darkGrey};
        }
      }
    p{
        max-height: 30px;
        line-height: 30px;
        font-size: 15px;
        text-align: center;
        ${Font.clarinVarSub2Bold}
        overflow: hidden;
    }
`