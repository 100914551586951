import { useEffect, useState } from "react";
import dictionary from "./dictionary";
import FutbolHome from "./FutbolHome";
import FutbolNews from "./FutbolNews";
import Tenis from "./Tenis";
import RugbyNews from "./RugbyNews";
import { Dummy } from "./style";

const api = "/api/mam";

const getMatch = async (match, sport) => {
  const response = await fetch(`${api}/${sport.toLowerCase()}/${match}`);
  const { data } = await response.json();
  if (data) return data[0];
  return null;
};

const MAM = (props) => {
  const [dataMatch, setDataMatch] = useState();
  let intervalMatch;

  const updateMatch = async () => {
    const data = await getMatch(
      props.match,
      props.sport.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    );
    if (data) {
      if (!dictionary[
        props.sport
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
      ].status[data.status.toLowerCase()]?.active) {
        clearInterval(intervalMatch);
      }
      setDataMatch(data);
    }
  };

  useEffect(() => {
    updateMatch();
    intervalMatch = setInterval(() => {
      updateMatch();
    }, 30000);
    return () => {
      clearInterval(intervalMatch);
    };
  }, [props.match, props.sport]);
  
  if(props?.isPreview && props.type == "home"){
    return(<Dummy><p>MAM {dataMatch?.home?.name} vs {dataMatch?.away?.name} </p></Dummy>)
  }
  
  return (

    
    <>
      {dataMatch && dataMatch.deporte?.name == 'Tenis' && (
        <Tenis data={dataMatch} type={props.type} bannerId={props.bannerId} />
      )}
      {dataMatch && dataMatch.deporte?.name == 'Fútbol' && props.type == "home" && (
        <FutbolHome data={dataMatch} type={props.type} bannerId={props.bannerId} />
      )}
      {dataMatch && dataMatch.deporte?.name == 'Fútbol' && props.type == "news" && (
        <FutbolNews data={dataMatch} type={props.type} bannerId={props.bannerId} />
      )}
      {dataMatch && dataMatch.deporte?.name == 'Rugby' && (
        <RugbyNews data={dataMatch} type={props.type} bannerId={props.bannerId} />
      )}
    </>
  );
};

export default MAM;
