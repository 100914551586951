import Clock from "../Clock";
import dictionary from "../dictionary";
import { FutbolCard } from "./style";
const status = dictionary.futbol.status;
const period = dictionary.futbol.period;

const FutbolHome = ({ data }) => {

  const getStatus = () => {
    if (data.status === "Fixture") {      
      return <>Inicio</>
    } else if(period[data?.match_period?.toLowerCase()]?.clockActive) {
      return <>{getPeriod()}</>;        
    } else {
      return <>{status[data?.status?.toLowerCase()]?.name}</>
    }
  };

  const getPeriod = () => {
    return (
      <>
        {period[data?.match_period?.toLowerCase()]?.description}{/*  - <Clock start={data.start_period} /> */}
      </>
    );
  };

  const getResult = () => {
    if (status[data?.status?.toLowerCase()]?.name === "fixture") {
      return <div className="futbol-header-info-time">
        <p>{data?.time?.slice(0, 5)}</p>
      </div>
    }
    return (
      <div className="futbol-header-info-goals">
        <div className={`futbol-header-info-local`}>
          <p>{data?.home?.score} {(data?.home?.golesDefPenales > 0 || data?.away?.golesDefPenales > 0) && <sub>{`(${data?.home?.golesDefPenales})`}</sub> }</p>
        
        </div>
        <div className={`futbol-header-info-visit`}>
          <p>{data.away.score}          {(data.home.golesDefPenales > 0 || data.away.golesDefPenales > 0) && <sub>{`(${data.away.golesDefPenales})`}</sub>}</p>

        </div>
      </div>
    );
  };

  return (
    <FutbolCard>
      <div className="futbol-header">
        <div className="futbol-header-team local">
          <p className="futbol-team-name">{data.home.name}</p>
        </div>
        <div className={`futbol-header-info ${status[data?.status?.toLowerCase()]?.class}`}>
          <p className="futbol-header-info-text">{getStatus()}</p>
          <div className="futbol-header-info-details">
            {getResult()}
          </div>
        </div>
        <div className="futbol-header-team visit">
          <p className="futbol-team-name">{data.away.name}</p>
        </div>
      </div>
    </FutbolCard>
  );
};

export default FutbolHome;
