const status =  {
    playing: {
        name: 'En vivo',
        active: true,
        class: 'in-progress'
    },
    halftime: {
        name: 'En vivo',
        active: true,
        class: 'in-progress'
    },
    fixture: {
        name: "fixture",
        active: true,
        class: 'start'
    },
    postponed: {
        name: 'Postergado',
        active: false,
        class: 'finalized'
    },
    suspende: {
        name: 'Suspendido',
        active: false,
        class: 'finalized'
    },
    cancelled: {
        name: 'Cancelado',
        active: false,
        class: 'finalized'
    },
    bye: {
        name: 'Cancelado',
        active: false,
        class: 'finalized'
    },
    played: {
        name: 'Final',
        active: false,
        class: 'finalized'
    },
    retired: {
        name: 'Final',
        active: false,
        class: 'finalized'
    },
    walkover: {
        name: 'Final',
        active: false,
        class: 'finalized'
    },
    result: {
        name: 'Final',
        active: false,
        class: 'finalized'
    }
}

export default {
    futbol: {
        status: status,
        period: {
            "1h": {
                description: "1T",
                active: true,
                clockActive: true,
            },
            "2h": {
                description: "2T",
                active: true,
                clockActive: true,
            },
            "ht": {
                description: "ET",
                active: true,
                clockActive: false,
            },
            "e1": {
                description: "1TS",
                active: true,
                clockActive: true,
            },
            "e2": {
                description: "2TS",
                active: true,
                clockActive: true,
            },
            "eh": {
                description: "ETS",
                active: true,
                clockActive: false,
            },
            "ps": {
                description: "Penales",
                active: true,
                clockActive: false,
            },
            "2ht": {
                description: "F",
                active: false,
                clockActive: false,
            },
            "ft": {
                description: "F",
                active: false,
                clockActive: false,
            }
        }
    },
    tenis: {
        status: status,
    },
    rugby: {
        status: {
            'first half': {
                name: 'En Vivo',
                perdiod: '1º PT',
                active: true,
                class: 'in-progress'
            },
            'second half': {
                name: 'En Vivo',
                perdiod: '2º ST',
                active: true,
                class: 'in-progress'
            },
            'halftime': {
                name: 'En Vivo',
                perdiod: 'ET',
                active: true,
                class: 'in-progress'
            },
            'extra time first half': {
                name: 'En Vivo',
                perdiod: '1º ET',
                active: true,
                class: 'in-progress'
            },
            'extra time second half': {
                name: 'En Vivo',
                perdiod: '2º ET',
                active: true,
                class: 'in-progress'
            },
            'extra time half time': {
                name: 'En Vivo',
                perdiod: 'ETS',
                active: true,
                class: 'in-progress'
            },
            'shoot out': {
                name: 'En Vivo',
                perdiod: 'Penales',
                active: true,
                class: 'in-progress'
            },
            'sudden death': {
                name: 'En Vivo',
                perdiod: 'Muerte Súbita',
                active: true,
                class: 'in-progress'
            },
            'fixture': {
                name: 'fixture',
                perdiod: 'Inicio',
                active: true,
                class: 'fixture',
            },
            'team in': {
                name: 'fixture',
                perdiod: 'Inicio',
                active: true,
                class: 'fixture',
            },
            'postponed': {
                name: 'Postergado',
                perdiod: 'Postergado',
                active: false,
                class: 'finalized'
            },
            'abandoned': {
                name: 'Abandonado',
                perdiod: 'Abandonado',
                active: false,
                class: 'finalized'
            },
            'ft': {
                name: 'Final',
                perdiod: 'F',
                active: false,
                class: 'finalized'
            },
            'result': {
                name: 'Final',
                perdiod: 'F',
                active: false,
                class: 'finalized'
            },
            'cancelled': {
                name: 'Cancelado',
                perdiod: 'Cancelado',
                active: false,
                class: 'finalized'
            }
        }
    }
};