import styled from "styled-components";
import { Color, Font, breakpoints, ColorDark } from "@/shared/Constants";

export const MamTenisBox = styled.div`
  ${(props) =>
    props.type == "home" &&
    `position: absolute;
    bottom: -25px;
    z-index: 2;
  `};
  ${(props) => props.status == "fixture" && "width: 100%;"};
  s
  /*${(props) => props.type == "notav1" && " width:50%; position:inherit;"};
  ${(props) => props.type == "twoxonevisual" && " width:70%"};
  ${breakpoints.tablet} {
    ${(props) => props.type == "twoxonevisual" && " width:100%"};
  }*/
  ${breakpoints.phone} {
  }
  ${(props) =>
    props.type == "news" &&
    `position:relative; 
    bottom: 0;
    left: 0; 
    margin: 5px 0px;
    margin-left: calc((100% - 898px - 1rem) / 2);
    max-width: 600px;
    width: 70%;  
    `};
  ${breakpoints.phone} {
    ${(props) =>
      props.type == "news" &&
      `
    margin-left: 0;
    max-width: 100%;
    width: 100%;`};
  }

  .box-fixture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
    ${breakpoints.phone} {
    }
    ${(props) =>
      props.type == "home" &&
      `background: ${Color.black000};
      p {
      color: ${Color.white};
      padding: 3px;
      font-size: 13px;
      line-height: 14px;
      ${Font.sansBold};
      }
      .home {
        text-align: right;
      }
      .away {
        text-align: left;
      }
      .game-info {
        background-color: ${Color.greyf1f1f1};
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        min-width: 50px;
        p {
          color: ${Color.black000};
          padding: 7px;
          ${breakpoints.darkMode} {
                [data-theme='dark'] & {
            color: ${ColorDark.textLight};
    }
          }
        }
        .game-info-text {
          position: absolute;
          bottom: 28px;
          right: 50%;
          transform: translateX(50%);
          background-color: ${Color.greyf1f1f1};
          width: 100%;
          text-align: center;
        }
      }
      .status {
        background: #000;
        padding: 3px;
      }
    `};
    ${(props) =>
      props.type == "news" &&
      `.player-name {
          display: flex;
          align-items: center;
          justify-content: center;
          p {
            font-size: 26px;
            line-height: 21px;
            ${Font.sansSemiBold};
            ${breakpoints.phone} {
              font-size: 18px;
              line-height: 21px;
            }
          }
        }
        .game-info-news{
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .game-info-day{
            font-size: 14px;
            line-height: 20px;
            ${Font.sansRegular};
            color: ${Color.grey};
            padding-bottom: 30px;
            ${breakpoints.phone} {
              font-size: 12px;
              padding-bottom: 20px;
            }
            ${breakpoints.darkMode} {
                [data-theme='dark'] & {
              color: ${ColorDark.textLight};
    }
            }
          }
          .game-info-hour-container{
            background-color: ${Color.grey};
            border-radius: 5px;
            padding: 15px;
            margin-bottom: 45px;
            .game-info-hour{
              font-size: 40px;
              line-height: 35px;
              ${Font.sansBold};
              color: ${Color.white};
              ${breakpoints.phone} {
                font-size: 30px;
                line-height: 26px;
              }
              ${breakpoints.darkMode} {
                [data-theme='dark'] & {
                color: ${ColorDark.textLight};
    }
              }
            }
          }
        }
      `};
  }

  .box-initialized-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    .game-info-text-container {
      margin-top: 20px;
      .game-info-text p,
      .only-mobile,
      .only-mobile p {
        width: auto;
        text-align: center;
        font-size: 14px;
        line-height: 20px;
        ${Font.sansRegular};
        color: ${Color.black000};
        text-transform: uppercase;
        ${breakpoints.phone} {
          font-size: 12px;
          line-height: 18px;
        }
        ${breakpoints.darkMode} {
          [data-theme='dark'] & {
          color: ${ColorDark.textLight};
          }
        }
      }
      .only-mobile {
        display: none;
        ${breakpoints.phone} {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 3px;
        }
      }
    }

    .box-initialized {
      ${(props) =>
        props.type == "home" &&
        `display: flex;
      align-items: center;
      justify-content: space-between;
      background: ${Color.black000};
      color: ${Color.white};
      gap: 10px;
      position: relative;
      .game-players {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        padding-left: 8px;
        padding-right: 23px;
        ${breakpoints.darkMode} {
                [data-theme='dark'] & {
          color: ${ColorDark.textLight};
      }
        }
        .team {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .player-name-mobile {
            display: none;
          }
          .player-name {
            display: flex;
            align-items: center;
            justify-content: center;
            p {
              padding: 9px 3px;
              font-size: 13px;
              line-height: 14px;
              ${Font.sansBold};
            }
          }
        }
      }
      .game-sets {
        display: flex;
        justify-content: end;
        .game-set-number-container {
          width: 30px;
          position: relative;
          .game-set-number {
            background-color: ${Color.greyf1f1f1};
            ${Font.sansSemiBold};
            color: ${Color.black000};
            font-size: 11px;
            line-height: 11px;
            text-align: center;
            padding: 3px 9px;
            position: absolute;
            top: -17px;
            width: 100%;
            border-left: 1px solid ${Color.grey888};
          }
          .game-score {
            ${Font.sansBold};
            font-size: 16px;
            line-height: 26px;
            text-align: center;
            padding: 3px 9px;
            border-left: 1px solid ${Color.tagBkg};
          }
        }
      }
      .line {
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: ${Color.tagBkg};
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
      }
    `};
      ${(props) =>
        props.type == "news" &&
        `display: flex;
      align-items: flex-start;
      justify-content: center;
      color: ${Color.black000};
      gap: 10px;
      position: relative;
      padding: 35px 0px 30px;
      ${breakpoints.darkMode} {
                [data-theme='dark'] & {
        color: ${ColorDark.textLight};
      }
      }
      .team {
        display: flex;
        align-items: center;
        .player-name {
          display: flex;
          align-items: center;
          justify-content: center;
          ${breakpoints.phone} {
            display: none;
          }
          p {
            font-size: 26px;
            line-height: 21px;
            ${Font.sansSemiBold};
            ${breakpoints.phone} {
              font-size: 20px;
              line-height: 21px;
            }
          }
        }
        .player-name-mobile {
          display: none;
          ${breakpoints.phone} {
            display: flex;          
            p {
            font-size: 20px;
            line-height: 21px;
            ${Font.sansSemiBold};
            text-transform: uppercase;
            }
          }

        }
      }
      .local{
        margin-top: 15px;
      }
      .visitante{
        margin-top: auto;
        margin-bottom: 15px;
      }
      .game-sets {
        display: flex;
        justify-content: end;
        color: ${Color.white};
        position: relative;
        .line-news {
          position: absolute;
          width: 90%;
          height: 1px;
          background-color: ${Color.white};
          top: 50%;
          transform: translate(-50%, 50%);
          left: 50%;
          z-index: 10;
        }
        .game-set-number-container {
          position: relative;
          background-color: ${Color.black000};
          padding: 10px 0;
          &:first-of-type {
            border-radius: 5px 0px 0px 5px;
            padding-left: 10px;
            .game-set-number{
              width: calc(100% - 10px)!important;
            }
          }
          &:last-of-type {
            border-radius: 0px 5px 5px 0px;
            padding-right: 10px;
            .game-set-number{
              width: calc(100% - 10px)!important;
            }
          }
          &:last-of-type .game-set-number, 
          &:last-of-type .game-score {
              border-right: none;
            }
          .game-set-number {
            background-color: ${Color.greyf1f1f1};
            ${Font.sansSemiBold};
            color: ${Color.black000};
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            padding: 9px 19px;
            position: absolute;
            top: -34px;
            width:100%;
            border-right: 1px solid ${Color.white};
            ${breakpoints.phone} {
              font-size: 13px;
              line-height: 15px;
              top: -33px;
            }

          }
          .game-score {
            ${Font.sansBold};
            font-size: 28px;
            text-align: center;
            padding: 8px 16px;
            border-right: 1px solid ${Color.tagBkg};
            ${breakpoints.phone} {
              font-size: 26px;
            }
          }
        }
      }        
    `};
    }
  }
`;
