import { useEffect, useState } from "react";

const Clock = ({ data }) => {    
    const [valueClock, setValueClock] = useState();

    let start = "";

    data?.commentary?.map((item) => {
        if(item.eventType == "START THE MATCH"){
            start =  item.timestamp
        }
        if(item.eventType == "FINISH THE FIRST HALF"){
            start =  item.timestamp
        }
        if(item.eventType == "START THE SECOND HALF"){
            start =  item.timestamp
        }
        if(item.eventType == "START THE FIRST EXTRA HALF"){
            start =  item.timestamp
        }
        if(item.eventType == "START THE SECOND EXTRA HALF"){
            start =  item.timestamp
        }
    })

    useEffect(() => {
        setInterval(() => {
            const dateInitial = new Date(start * 1000);
            const dateCurrent = new Date();
            dateCurrent.toLocaleString('en-US', {
                timeZone: "America/Argentina/Buenos_Aires"
            })
            const diff = dateCurrent - dateInitial;
            const minutes = Math.floor(diff / (1000 * 60));
            const seconds = Math.floor((diff % (1000 * 60)) / 1000);
            setValueClock(minutes + ":" + parseInt(seconds).toLocaleString("en-US", { minimumIntegerDigits: 2 }));
        }, 1000);
    }, [])

    return <>{valueClock}</>
}

export default Clock;